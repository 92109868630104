import React from 'react';
import {withRouter} from 'react-router-dom';
import "../assets/css/HomeButton.scss";
import {replace, createSesion} from "./Utils";
import {ReactComponent as StrawberrySVG} from '../assets/images/HomeButtons/strawberry.svg';
import {ReactComponent as HandcuffsSVG} from '../assets/images/HomeButtons/handcuffs.svg';
import {ReactComponent as FloggerSVG} from '../assets/images/HomeButtons/flogger.svg';
import {ReactComponent as BodySVG} from '../assets/images/HomeButtons/body.svg';
import {ReactComponent as TailSVG} from '../assets/images/HomeButtons/tail.svg';
import {ReactComponent as MouthSVG} from '../assets/images/HomeButtons/mouth.svg';

class HomeButton extends React.Component {
    _createSession = (id) => {
        createSesion(id, (session) => {
            setTimeout(() => {
                this.props.history.push(session.group.name);
            }, 500)
        });
    }

    render() {
        return (
            <div className="home-btn" onClick={() => {
                this._createSession(this.props.id)
            }}>
                {this.props.image === "strawberry" && <StrawberrySVG/>}
                {this.props.image === "handcuffs" && <HandcuffsSVG/>}
                {this.props.image === "flogger" && <FloggerSVG/>}
                {this.props.image === "body" && <BodySVG/>}
                {this.props.image === "tail" && <TailSVG/>}
                {this.props.image === "mouth" && <MouthSVG/>}

                <h1>{replace(this.props.name)}</h1>
            </div>
        );
    }
}

export default withRouter(HomeButton);
