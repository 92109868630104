import {graphql} from 'babel-plugin-relay/macro'
import {commitMutation} from 'react-relay'
import environment from './Environment'

const mutation = graphql`
    mutation CreateSessionMutation($input: createSessionInput!) {
        createSession(input: $input) {
            session {
                group {
                    id
                    name
                }
                id
                uuid
            }
        }
    }
`

export default (group, callback) => {
    const variables = {
        input: {
            group,
            clientMutationId: ""
        }
    }

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback(response.createSession);
            },
            onError: err => console.error(err),
        },
    )
}