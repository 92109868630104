import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {graphql} from 'babel-plugin-relay/macro'
import {QueryRenderer} from 'react-relay'
import environment from '../Relay/Environment'
import Category from '../Components/Category'
import KinkOMeter from '../Components/KinkOMeter'
import '../assets/css/Group.scss';
import {withTranslation} from "react-i18next";
import {Trans} from 'react-i18next';
import {Helmet} from "react-helmet";
import Banner from '../Components/Banner';

const ViewQuery = graphql`
    query ViewQuery($groupName: String!, $sessionId: String!) {
        kinks(groups_name: $groupName) {
            edges {
                node {
                    id
                    name
                    description
                    type
                    image
                    weight
                    category {
                        id
                        name
                        description
                        image
                    }
                }
            }
        }
        picks(session_uuid: $sessionId) {
            edges {
                node {
                    id
                    session {
                        id
                    }
                    kink {
                        id
                    }
                    type
                    color
                    score
                    willing
                }
            }
        }
    }
`;

class View extends React.Component {
    constructor(props) {
        super(props);
        this.state = {groupName: props.match.params.group, sessionId: props.match.params.id};
    }

    pickColor = (color) => {
        this.setState({color: color});
    }

    render() {
        const groupName = this.props.match.params.id;
        return (
            <div>
                <QueryRenderer
                    environment={environment}
                    query={ViewQuery}
                    variables={{groupName: this.state.groupName, sessionId: this.state.sessionId}}
                    render={({error, props}) => {
                        if (error) {
                            console.error(error);
                            return <div>Error!</div>
                        }
                        if (!props) {
                            return <div className="loadingText"><Trans>Loading...</Trans></div>;
                        }

                        let categories = [];
                        let picks = props.picks.edges;
                        props.kinks.edges.forEach((kink, i) => {
                            let categoryIndex = categories.findIndex((element) => element.category.id === kink.node.category.id);
                            if (kink.node.category && categoryIndex === -1) {
                                categoryIndex = categories.push({category: kink.node.category, kinks: []}) - 1;
                            }

                            let findPicks = picks.filter((element) => {
                                return element.node.kink.id === kink.node.id
                            });

                            let currentKink = {
                                kink: kink.node,
                                picks: findPicks
                            };

                            let category = categories[categoryIndex];
                            category.kinks.push(currentKink);
                        });

                        return <div className="group-wrapper">
                            <Banner/>
                            <div className="fixed-header-view">
                                <div className="fixed-view-header-container">
                                    <div className="header-back">
                                        <Link to="/"><Trans>Home</Trans></Link>
                                    </div>
                                    <KinkOMeter kinkData={categories}/>
                                </div>
                            </div>

                            <div className="group-jars">
                                {categories.map((item, i) =>
                                    <Category
                                        key={i}
                                        id={item.category.id}
                                        group={groupName}
                                        name={item.category.name}
                                        kinks={item.kinks}
                                        color={this.state.color}
                                        noEvents={true}/>)}
                            </div>
                        </div>
                    }}
                />
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>My Kinks | Jar of Kinks</title>
                    <meta property="og:title" content="My Kinks | Jar of Kinks"/>
                    <meta property="og:description" content="Check out my jar of kinks!"/>
                    <meta name="description" content="Check out my jar of kinks!"/>
                </Helmet>

            </div>
        );
    }
}

export default withTranslation()(withRouter(View));