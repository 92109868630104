import {graphql} from 'babel-plugin-relay/macro'
import {commitMutation} from 'react-relay'
import environment from './Environment'

const mutation = graphql`
    mutation CreatePickMutation($input: createPickInput!) {
        createPick(input: $input) {
            pick {
                id
            }
        }
    }
`;

export default (state, callback) => {
    const variables = {
        input: {
            session: state.session.id,
            kink: state.kinkId,
            score: state.value,
            type: state.type,
            color: state.color,
            willing: state.willing,
            clientMutationId: ""
        }
    }

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback(response.createPick);
            },
            onError: err => console.error(err),
        },
    )
}