import React from 'react';
import "../assets/css/Settings.scss";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import settingsSVG from "../assets/images/settings.svg";

class Settings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            placement: this.props.placement || "left",
            copied: false
        };
    }

    _togglePopup = () => {
        this.setState({open: !this.state.open});
    }

    render() {
        const {url, title, quote} = this.state;

        const shareSettings = <div>
            Name: <input type="text"/>
            <div>

            </div>
        </div>;

        return <div>
            <div className="share-picker-button" onClick={() => this._togglePopup()}>
                <img className="share-picker-icon" alt="Share" src={settingsSVG}/>
            </div>
            <div className={"settings" + ((this.state.open) ? " active" : "")}>
                {shareSettings}
            </div>
        </div>;
    }
}

export default Settings;
