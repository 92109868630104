import React from 'react';
import "../assets/css/ColorPicker.css";

class ColorPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {color: props.color}
    }

    _setColor(color) {
        this.setState({color: color}, this.props.pickColor(color));
    }

    render() {
        const colors = [
            "#df19c1",
            "#e33056",
            "#4399de",
            "#23d513",
            "#ff6200",
            "#efc40e",
            "#ff0047",
        ];
        return (
            <div className="color-picker">
                {colors.map((color, i) => <div key={i} className={"color " + (this.state.color === color && "selected")} style={{backgroundColor: color}} onClick={() => this._setColor(color)}/>)}
            </div>
        );
    }
}

export default ColorPicker;
