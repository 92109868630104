import React from 'react';
import "../assets/css/Category.scss";
import Jar from "./Jar";
import {replace} from './Utils';
import questionSVG from "../assets/images/question.svg";
import {OverlayTrigger, Popover} from 'react-bootstrap';

class Category extends React.Component {

    constructor(props) {
        super(props);

        this.state = {noEvents: this.props.noEvents}
    }

    renderJar(index, kink) {
        const pickSelf = kink.picks.find((item) => item.node.type === 1);
        const pickPartner = kink.picks.find((item) => item.node.type === 2);

        let typeSelf, typePartner;
        switch (kink.kink.type) {
            case 3:
                typeSelf = "Giving";
                typePartner = "Receiving";
                break;
            case 4:
                typeSelf = "Dominant";
                typePartner = "Submissive";
                break;
            case 5:
                typeSelf = "Agressor";
                typePartner = "Target";
                break;
            default:
                typeSelf = "Self";
                typePartner = "Partner";
        }

        const title = <div className="jar-wrapper-title">
            {kink.kink.name}

            <OverlayTrigger rootClose
                            trigger="click"
                            key={`${kink.kink.id}-${kink.kink.type}`}
                            placement="bottom"
                            overlay={
                                <Popover id={`popover-${kink.kink.id}-${kink.kink.type}`}>
                                    <Popover.Title as="h3">{kink.kink.name}</Popover.Title>
                                    <Popover.Content>
                                        {(kink.kink.image) ? <img alt={kink.kink.name} src={'img/positions/' + kink.kink.image} className={'kinkImage'} /> : kink.kink.description}
                                    </Popover.Content>
                                </Popover>
                            }
            >
                <img alt="info" src={questionSVG}/>
            </OverlayTrigger>
        </div>;

        return (kink.kink.type === 1) ?
            <div key={index + "_1"} className="jar-wrapper">
                {title}
                <div className="jar-container">
                    <Jar id={kink.kink.id} name={kink.kink.name} description={kink.kink.description} type={1} color={this.props.color} pick={pickSelf} title="" noEvents={this.state.noEvents} reFetch={this.props.reFetch}/>
                </div>
            </div>
            :
            <div key={index + "_1"} className="jar-wrapper">
                {title}
                <div className="jar-container">
                    <Jar id={kink.kink.id} name={kink.kink.name} description={kink.kink.description} type={1} color={this.props.color} pick={pickSelf} title={typeSelf}
                         noEvents={this.state.noEvents} reFetch={this.props.reFetch}/>
                    <Jar id={kink.kink.id} name={kink.kink.name} description={kink.kink.description} type={2} color={this.props.color} pick={pickPartner} title={typePartner}
                         noEvents={this.state.noEvents} reFetch={this.props.reFetch}/>
                </div>
            </div>;
    }

    render() {
        return (
            <div className="category">
                <div className="category-title">{replace(this.props.name)}</div>
                <div className="category-jars">
                    {this.props.kinks.map((item, i) => this.renderJar(i, item))}
                </div>
            </div>
        );
    }
}

export default Category;
