import {Environment, RecordSource, Store} from 'relay-runtime';
import {cacheMiddleware, RelayNetworkLayer, urlMiddleware} from 'react-relay-network-modern/node8';

const network = new RelayNetworkLayer(
    [
        cacheMiddleware({
            size: 100, // max 100 requests
            ttl: 900000, // 15 minutes
        }),
        urlMiddleware({
            url: (req) => Promise.resolve('https://api.jarofkinks.com:444/graphql')
            //url: (req) => Promise.resolve('https://127.0.0.1:8000/graphql')
        }),
        (next) => async (req) => {
            req.fetchOpts.headers['X-LOCALE'] = localStorage.getItem('i18nextLng');
            return await next(req);
        }
    ]
);

const source = new RecordSource();
const store = new Store(source);
const environment = new Environment({network, store});

export default environment;