import React from 'react';
import {Route, Switch} from "react-router-dom";
import Home from './Screens/Home';
import Group from './Screens/Group';
import View from './Screens/View';
import './i18n';

function App() {
    return (
        <Switch>
            <Route exact={true} path="/" component={Home}/>
            <Route path="/view/:group/:id" component={View}/>
            <Route path="/:id" component={Group}/>
        </Switch>
    );
}

export default App;
