import React from 'react';
import "../assets/css/LanguageSelector.scss";
import i18n from 'i18next';
import brazilFlag from '../assets/images/flags/brazil.png';
import usaFlag from '../assets/images/flags/usa.png';

class LanguageSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    _changeLanguage = (lng) => {
        i18n.changeLanguage(lng).then(() => {
            window.location.reload();
        });
    }


    render() {
        const language = localStorage.getItem('i18nextLng');
        return <div className="language-wrapper">
            <button className={(language === "en") ? "active" : ""} onClick={() => this._changeLanguage('en')}><img src={usaFlag} alt="English"/></button>
            <button className={(language.toLowerCase() === "pt-br") ? "active" : ""} onClick={() => this._changeLanguage('pt-BR')}><img src={brazilFlag} alt="Portuguese"/></button>
        </div>;
    }
}

export default LanguageSelector;
