import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {graphql} from 'babel-plugin-relay/macro'
import {fetchQuery, QueryRenderer} from 'react-relay'
import environment from '../Relay/Environment'
import Category from '../Components/Category'
import ColorPicker from '../Components/ColorPicker'
import ShareIcons from '../Components/ShareIcons'
import Settings from '../Components/Settings'
import KinkOMeter from '../Components/KinkOMeter'
import {getSession} from "../Components/Utils";
import '../assets/css/Group.scss';
import {withTranslation} from "react-i18next";
import {Trans} from 'react-i18next';
import Banner from '../Components/Banner';

const GroupQuery = graphql`
    query GroupQuery($groupName: String!, $sessionId: String!){
        kinks(groups_name: $groupName) {
            edges {
                node {
                    id
                    name
                    description
                    type
                    image
                    weight
                    category {
                        id
                        name
                        description
                        image
                    }
                }
            }
        }
        picks(session: $sessionId) {
            edges {
                node {
                    id
                    session {
                        id
                    }
                    kink {
                        id
                    }
                    type
                    color
                    score
                    willing
                }
            }
        }
    }
`;

class Group extends React.Component {
    constructor(props) {
        super(props);

        const session = getSession(props.match.params.id);
        const groupName = this.props.match.params.id;
        if (!session) {
            this.props.history.push("/");
            this.props.history.go(0);
        }

        this.state = {
            color: "#df19c1", session: session, groupName: groupName, data: null
        };
    }

    componentDidMount() {
        this.reFetch(this.state.groupName, this.state.session.id);
    }

    pickColor = (color) => {
        this.setState({color: color});
    }

    reFetch = (groupName, sessionId) => {
        fetchQuery(environment, GroupQuery, {groupName: groupName, sessionId: sessionId}, {force: true})
            .then(data => {
                let categories = [];
                let picks = data.picks.edges;
                data.kinks.edges.forEach((kink, i) => {
                    let categoryIndex = categories.findIndex((element) => element.category.id === kink.node.category.id);
                    if (kink.node.category && categoryIndex === -1) {
                        categoryIndex = categories.push({category: kink.node.category, kinks: []}) - 1;
                    }

                    let findPicks = picks.filter((element) => {
                        return element.node.kink.id === kink.node.id
                    });

                    let currentKink = {
                        kink: kink.node, picks: findPicks
                    };

                    let category = categories[categoryIndex];
                    category.kinks.push(currentKink);
                });

                this.setState({
                    data: categories
                });
            });
    }

    render() {
        const groupName = this.props.match.params.id;

        if (!this.state.data) {
            return <div className="loadingText"><Trans>Loading...</Trans></div>;
        }

        return <div className="group-wrapper">
            <Banner/>
            <div className="fixed-header">
                <div className="fixed-header-container">
                    <KinkOMeter kinkData={this.state.data}/>
                </div>
                <div className="fixed-header-container">
                    <div className="header-back">
                        <Link to="/"><Trans>Back</Trans></Link>
                    </div>
                    <div className="color-wrapper">
                        <ColorPicker color={this.state.color} pickColor={this.pickColor}/>
                    </div>
                    <div className="shareIcons-wrapper">
                        <Settings uuid={this.state.session.uuid}/>
                        <ShareIcons url={"https://www.jarofkinks.com/view/" + groupName + "/" + this.state.session.uuid}
                                    title={"Jar of Kinks - " + groupName}/>
                    </div>
                </div>
            </div>

            <div className="group-jars">
                {this.state.data.map((item, i) => <Category
                    key={i}
                    id={item.category.id}
                    group={groupName}
                    name={item.category.name}
                    kinks={item.kinks}
                    color={this.state.color}
                    reFetch={this.reFetch}/>)}
            </div>
        </div>
    }
}

export default withTranslation()(withRouter(Group));