import React from 'react';
import {useEffect, useRef} from 'react';
import "../assets/css/Banner.scss";

class Banner extends React.Component {

    constructor(props) {
        super(props);

        this.bannerDiv = React.createRef();
        this.atOptions = {
            key: (window.innerWidth > 728) ? "9cf689b9ce4db02901621895fbee8ac8" : "75ae01d3c56d0dcd3760583f600ec903",
            format: 'iframe',
            height: (window.innerWidth > 728) ? 90 : 50,
            width: (window.innerWidth > 728) ? 728 : 320,
            params: {},
        }
        this.style = {
            width: this.atOptions.width + "px",
            height: this.atOptions.height + "px"
        }
    }

    componentDidMount() {
        if (!this.bannerDiv.current.firstChild) {
            const conf = document.createElement('script');
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `//www.topcreativeformat.com/${this.atOptions.key}/invoke.js`;
            conf.innerHTML = `atOptions = ${JSON.stringify(this.atOptions)}`;
            this.bannerDiv.current.append(conf);
            this.bannerDiv.current.append(script);
        }

    }

    render() {
        return <div style={{textAlign: "center"}}>
            <div className="bannerAd" style={this.styles} ref={this.bannerDiv}></div>
        </div>
    }
}

export default Banner;