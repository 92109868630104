import React from "react";
import "../assets/css/KoFi.css";
import { useTranslation } from 'react-i18next';

export default function KoFi(props) {
    const { id, label } = props;
    const { t } = useTranslation();

    return (
        <div className="btn-container">
            <a
                title={label}
                className="kofi-button"
                href={"https://ko-fi.com/" + id}
                target="_blank"
                rel="noopener noreferrer"
            >
        <span className="kofitext">
          <img
              src="https://ko-fi.com/img/cup-border.png"
              className="kofiimg"
              alt="Ko-Fi button"
          />
            {t('Donate')}
        </span>
            </a>
        </div>
    );
}