import React from 'react';
import {withRouter} from 'react-router-dom';
import "../assets/css/KinkOMeter.scss";
import {IgrLinearGauge} from 'igniteui-react-gauges';
import {IgrLinearGraphRange} from 'igniteui-react-gauges';
import {LinearGraphNeedleShape} from 'igniteui-react-gauges';
import {IgrLinearGaugeModule} from 'igniteui-react-gauges';
import {getSession} from "./Utils";

class KinkOMeter extends React.Component {

    constructor(props) {
        super(props);

        this.onGaugeRef = this.onGaugeRef.bind(this);
        //this.onAnimateToGauge3 = this.onAnimateToGauge3.bind(this);
    }

    onGaugeRef(component) {
        if (!component) {
            return;
        }

        this.gauge = component;
        //this.onAnimateToGauge3(null);
    }

    /*onAnimateToGauge3 = (e) => {
        if (!this.gauge) { return; }

        this.gauge.minimumValue = 0;
        this.gauge.maximumValue = 100;
        this.gauge.value = 90;
        this.gauge.interval = 10;
    }*/

    render() {
        let totalWeight = 0;
        let totalPicks  = 0;

        if (this.props.kinkData) {
            this.props.kinkData.forEach(category => {
                category.kinks.forEach(kinkData => {
                    totalWeight += kinkData.kink.weight;

                    let picksScore = 0;
                    kinkData.picks.forEach(pickData => {
                        let score = pickData.node.score;
                        if (kinkData.kink.type > 1) {
                            score = score / 2; // Self and Partner
                        }
                        picksScore += (score / 100) * kinkData.kink.weight;
                    });

                    totalPicks += picksScore;
                });
            });
        }

        let progressValue = (totalPicks / totalWeight) * 100;

        if (progressValue > 99) {
            progressValue = 99.8;
        }

        console.log("TOTAL WEIGHT", totalWeight);
        console.log("TOTAL PICKS", totalPicks);
        console.log("PROGRESS VALUE", progressValue);

        return (
            <div className="kinkOMeter">
                <IgrLinearGauge
                    ref={this.onGaugeRef}
                    transitionDuration={100}
                    height="30px"
                    width="100%"
                    minimumValue={0}
                    maximumValue={100}
                    value={progressValue}
                    interval={10}
                    labelInterval={10}
                    labelExtent={0}

                    minorTickEndExtent={0.10}
                    minorTickStartExtent={0.20}
                    tickStartExtent={0.25}
                    tickEndExtent={0.05}
                    tickStrokeThickness={1}

                    needleShape="Triangle"
                    needleBrush="#00B4E6FF"
                    needleOutline="#FFF"
                    scaleStrokeThickness={0}
                    scaleBrush="#ffffff"
                    scaleOutline="#d3d3d3"

                    font="10px Arial"
                    fontBrush="#FFF"

                    backingBrush="transparent"

                    backingStrokeThickness={0}
                    rangeBrushes="#ADE600AF, #E6C700AF, #E66800AF, #E61B00AF"
                    rangeOutlines="#ADE600AF, #E6C700AF, #E66800AF, #E61B00AF">
                    <IgrLinearGraphRange name="range1" startValue={0} endValue={25}/>
                    <IgrLinearGraphRange name="range2" startValue={25} endValue={50}/>
                    <IgrLinearGraphRange name="range3" startValue={50} endValue={75}/>
                    <IgrLinearGraphRange name="range4" startValue={75} endValue={100}/>
                </IgrLinearGauge>
            </div>
        );
    }
}

export default withRouter(KinkOMeter);
