import {graphql} from 'babel-plugin-relay/macro'
import {commitMutation} from 'react-relay'
import environment from './Environment'

const mutation = graphql`
    mutation UpdatePickMutation($input: updatePickInput!) {
        updatePick(input: $input) {
            pick {
                id
            }
        }
    }
`

export default (state, callback) => {
    const variables = {
        input: {
            id: state.pickId,
            score: state.value,
            color: state.color,
            willing: state.willing,
            clientMutationId: ""
        }
    }

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback(response.updatePick);
            },
            onError: err => console.error(err),
        },
    )
}