import React from 'react';
import "../assets/css/ShareIcons.scss";
import {ReactComponent as LinkSVG} from "../assets/images/link.svg";
import {
    FacebookIcon,
    FacebookShareButton,
    FacebookMessengerIcon,
    LineIcon,
    LineShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import shareSVG from "../assets/images/share.svg";

class ShareIcons extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            url: this.props.url || "https://www.jarofkinks.com",
            title: this.props.title || "Jar of Kinks",
            quote: this.props.quote || "JarofKinks",
            showAll: this.props.showAll,
            placement: this.props.placement || "left",
            copied: false
        };
    }

    _togglePopup = () => {
        this.setState({open: !this.state.open});
    }

    _copyCodeToClipboard = () => {
        var dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.setAttribute('value', this.state.url);
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);

        this.setState({copied: true}, () => {
            setTimeout(() => {
                this.setState({copied: false})
            }, 1000);
        });
    }

    render() {
        const {url, title, quote} = this.state;

        const shareButtons = <div>
            <OverlayTrigger rootClose
                            key={`share-link`}
                            placement={this.state.placement}
                            trigger={['hover', 'focus', 'click']}
                            delay={{show:250, hide:400}}
                            overlay={
                                <Tooltip id={`tooltip-share-link`}>
                                    {this.state.copied ? <div>Copied</div> : <div>Share your custom <strong>URL</strong></div>}
                                </Tooltip>}>
                <button className="share-link-button" onClick={() => this._copyCodeToClipboard()}>
                    <LinkSVG/>
                </button>
            </OverlayTrigger>
            <OverlayTrigger rootClose
                            key={`share-facebook`}
                            placement={this.state.placement}
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip id={`tooltip-share-facebook`}>
                                    Share on <strong>facebook</strong>
                                </Tooltip>}>
                <FacebookShareButton url={url} quote={quote} className="">
                    <FacebookIcon size={32} round={true}/>
                </FacebookShareButton>
            </OverlayTrigger>

            <OverlayTrigger rootClose
                            key={`share-facebook-messenger`}
                            placement={this.state.placement}
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip id={`tooltip-share-facebook-messenger`}>
                                    Share on <strong>messenger</strong>
                                </Tooltip>}>
                <button className="react-share__ShareButton" style={{background: 'transparent', border: 'none', padding: '0px', cursor: 'pointer'}}
                        onClick={() => {
                            window.location.href = 'fb-messenger://share?link=' + encodeURIComponent(url) + '&app_id=' + encodeURIComponent(3136575593095605)
                        }}
                >
                    <FacebookMessengerIcon size={32} round={true}/>
                </button>
            </OverlayTrigger>

            <OverlayTrigger rootClose
                            key={`share-twitter`}
                            placement={this.state.placement}
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip id={`tooltip-share-twitter`}>
                                    Share on <strong>twitter</strong>
                                </Tooltip>}>
                <TwitterShareButton url={url} title={title} className="">
                    <TwitterIcon size={32} round={true}/>
                </TwitterShareButton>
            </OverlayTrigger>

            <OverlayTrigger rootClose
                            key={`share-whatsappp`}
                            placement={this.state.placement}
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip id={`tooltip-share-whatsapp`}>
                                    Share on <strong>whatsapp</strong>
                                </Tooltip>}>
                <WhatsappShareButton url={url} title={title} separator=":: " className="">
                    <WhatsappIcon size={32} round={true}/>
                </WhatsappShareButton>
            </OverlayTrigger>

            <OverlayTrigger rootClose
                            key={`share-line`}
                            placement={this.state.placement}
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip id={`tooltip-share-line`}>
                                    Share on <strong>line</strong>
                                </Tooltip>}>
                <LineShareButton url={url} title={title} className="">
                    <LineIcon size={32} round={true}/>
                </LineShareButton>
            </OverlayTrigger>

            <OverlayTrigger rootClose
                            key={`share-telegram`}
                            placement={this.state.placement}
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip id={`tooltip-share-telegram`}>
                                    Share on <strong>telegram</strong>
                                </Tooltip>}>
                <TelegramShareButton url={url} title={title} className="">
                    <TelegramIcon size={32} round={true}/>
                </TelegramShareButton>
            </OverlayTrigger>

            <OverlayTrigger rootClose
                            key={`share-viber`}
                            placement={this.state.placement}
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip id={`tooltip-share-viber`}>
                                    Share on <strong>viber</strong>
                                </Tooltip>}>
                <ViberShareButton url={url} title={title} className="">
                    <ViberIcon size={32} round={true}/>
                </ViberShareButton>
            </OverlayTrigger>

            <OverlayTrigger rootClose
                            key={`share-reddit`}
                            placement={this.state.placement}
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip id={`tooltip-share-reddit`}>
                                    Share on <strong>reddit</strong>
                                </Tooltip>}>
                <RedditShareButton url={url} title={title} windowWidth={660} windowHeight={460} className="">
                    <RedditIcon size={32} round={true}/>
                </RedditShareButton>
            </OverlayTrigger>
        </div>;

        return (this.state.showAll) ? shareButtons : <div>
            <div className="share-picker-button" onClick={() => this._togglePopup()}>
                <img className="share-picker-icon" alt="Share" src={shareSVG}/>
            </div>
            <div className={"share-picker" + ((this.state.open) ? " active" : "")}>
                {shareButtons}
            </div>
        </div>;
    }
}

export default ShareIcons;
