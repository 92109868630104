import React from 'react';
import CreateSessionMutation from "../Relay/CreateSessionMutation";
import CreatePickMutation from "../Relay/CreatePickMutation";
import UpdatePickMutation from "../Relay/UpdatePickMutation";

export function replace(string) {
    if (string.length < 3) {
        return string;
    }

    let randIndex = null;
    do {
        randIndex = Math.floor(Math.random() * Math.floor(string.length));
    } while (string[randIndex] === " ")

    return string.split("").map((char, i) => (i === randIndex) ? <span key={"cf_" + i} className="first">{char}</span> : char);
}

export function createSesion(group, callback) {
    const sessionArray = JSON.parse(localStorage.getItem('sessions')) || [];
    const found = sessionArray.find((item) => item.group.id === group);
    if (!found) {
        CreateSessionMutation(group, (response) => {
            sessionArray.push(response.session);
            localStorage.setItem('sessions', JSON.stringify(sessionArray));
            callback(response.session);
        });
    } else {
        callback(found);
    }
}

export function setValue(state, callback) {
    if (!state.pickId) {
        CreatePickMutation(state, (response) => {
            callback(response);
        });
    } else {
        UpdatePickMutation(state, (response) => {
            callback(response);
        });
    }
}

export function getSession(name) {
    const sessionArray = JSON.parse(localStorage.getItem('sessions')) || [];
    return sessionArray.find((item) => item.group.name === name);
}

