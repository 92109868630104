/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GroupQueryVariables = {|
  groupName: string,
  sessionId: string,
|};
export type GroupQueryResponse = {|
  +kinks: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +description: string,
        +type: number,
        +image: ?string,
        +weight: number,
        +category: ?{|
          +id: string,
          +name: ?string,
          +description: ?string,
          +image: ?string,
        |},
      |}
    |}>
  |},
  +picks: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +session: {|
          +id: string
        |},
        +kink: {|
          +id: string
        |},
        +type: number,
        +color: ?string,
        +score: number,
        +willing: ?number,
      |}
    |}>
  |},
|};
export type GroupQuery = {|
  variables: GroupQueryVariables,
  response: GroupQueryResponse,
|};
*/


/*
query GroupQuery(
  $groupName: String!
  $sessionId: String!
) {
  kinks(groups_name: $groupName) {
    edges {
      node {
        id
        name
        description
        type
        image
        weight
        category {
          id
          name
          description
          image
        }
      }
    }
  }
  picks(session: $sessionId) {
    edges {
      node {
        id
        session {
          id
        }
        kink {
          id
        }
        type
        color
        score
        willing
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "groupName",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sessionId",
    "type": "String!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v6 = [
  (v1/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "groups_name",
        "variableName": "groupName"
      }
    ],
    "concreteType": "KinkConnection",
    "kind": "LinkedField",
    "name": "kinks",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KinkEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Kink",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Category",
                "kind": "LinkedField",
                "name": "category",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "session",
        "variableName": "sessionId"
      }
    ],
    "concreteType": "PickConnection",
    "kind": "LinkedField",
    "name": "picks",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PickEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Pick",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Session",
                "kind": "LinkedField",
                "name": "session",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Kink",
                "kind": "LinkedField",
                "name": "kink",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "score",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "willing",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GroupQuery",
    "selections": (v7/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GroupQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "GroupQuery",
    "operationKind": "query",
    "text": "query GroupQuery(\n  $groupName: String!\n  $sessionId: String!\n) {\n  kinks(groups_name: $groupName) {\n    edges {\n      node {\n        id\n        name\n        description\n        type\n        image\n        weight\n        category {\n          id\n          name\n          description\n          image\n        }\n      }\n    }\n  }\n  picks(session: $sessionId) {\n    edges {\n      node {\n        id\n        session {\n          id\n        }\n        kink {\n          id\n        }\n        type\n        color\n        score\n        willing\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4e5626ec6a6b4e9e337004dcb4a1db0c';

module.exports = node;
